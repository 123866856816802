<!--  -->
<template>
	<div class="erp">
		<div class="swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="content-box">
						<div>
							<img class="phone-dn" src="@/assets/production/img_ems.png" alt="" />
                            <div style="height:125px"></div>
                            <h1 style="text-align: center;" class="phone-db">物联网生产管理系统</h1>
                            <p style="text-align: center;padding:0 10px;" class="phone-db">基于物联网的生产管理系统，生产设备数据实时反馈，基于设备物联数据闭环管理,智能化派工</p>
                            <img
								style="padding-top: 30px"
								class="phone-db"
								src="@/assets/h5-production/img_mes_zw.png"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="painPoint" style="padding-top:100px">
			<div class="commons-title" :class="advantageAnimate">
				工单系统的<span>痛点</span>和<span>疑问</span>
			</div>
			<div class="commons-title-label" :class="advantageAnimate">
				传统的工单系统中，收集出当前存在的问题
			</div>
            <ul :class="advantageAnimate">
                <li>
                    <div class="icon-box">
                        <img src="@/assets/production/icon_xxgdxy.png" alt="" />
                    </div>
                    <div class="title">
                        信息孤岛效应
                    </div>
                    <div class="content-text">
                        不同的部门和工作岗位之间信息不畅通,无法实现全面集成的生产管理。
                    </div>
                </li>
                <li>
                    <div class="icon-box">
                        <img src="@/assets/production/icon_scjhbmc.png" alt="" />
                    </div>
                    <div class="title">
                        生产计划不准确
                    </div>
                    <div class="content-text">
                        人工排程难以全面考虑限制条件,生产计划容易偏离实际情况,无法实现优化。
                    </div>
                </li>
                <li>
                    <div class="icon-box">
                        <img src="@/assets/production/icon_zlkzbyg.png" alt="" />
                    </div>
                    <div class="title">
                        质量控制不严格
                    </div>
                    <div class="content-text">
                        人工检测和管理难以实现系统的质量控制,产品质量难以保证。
                    </div>
                </li>
                <li>
                    <div class="icon-box">
                        <img src="@/assets/production/icon_sbwhbdw.png" alt="" />
                    </div>
                    <div class="title">
                        设备维护不到位
                    </div>
                    <div class="content-text">
                        依赖人工很难做到实时监控关键设备的运行状态,难以预测设备故障和实现预防性维护。
                    </div>
                </li>
                <li>
                    <div class="icon-box">
                        <img src="@/assets/production/icon_kckzbjz.png" alt="" />
                    </div>
                    <div class="title">
                        库存控制不精准
                    </div>
                    <div class="content-text">
                        人工管理库存容易导致库存水平过高或短缺,难以实现按需配送。
                    </div>
                </li>
                <li>
                    <div class="icon-box">
                        <img  src="@/assets/production/icon_sccbwfjzjs.png" alt="" />
                    </div>
                    <div class="title">
                        生产成本无法精准计算
                    </div>
                    <div class="content-text">
                        上述种种因素,会导致人工生产管理的生产成本显著高于自动化MES管理,竞争力减弱。
                    </div>
                </li>
            </ul>
		</div>
		<div class="zhendui">
            <div class="zhendui-content">
                <div class="commons-title"  :class="programmeAnimete" style="margin-bottom:100px;">
                    普通MES和IOT-MES的差异性
                </div>
                <div class="center-icon" :class="programmeAnimete">
                    <img src="../assets/production/img_vs.png" alt="">
                </div>
                <img style="width: 100%;" class="phone-db" src="../assets/h5-production/img_pumes_zw.png" alt="">
                <div class="content-warp phone-dn" :class="programmeAnimete">
                    <div class="left-box">
                        <div class="title">
                            普通MES
                        </div>
                        <ul>
                            <li v-for="i in zhenduiLeftData" :key="i.label">
                                <div class="icon-box">
                                    <img src="../assets/production/x.png" alt="">
                                </div>
                                <div class="content-text">
                                    <strong>{{i.label}}</strong>：{{i.text}}
                                </div>
                                
                            </li>
                            
                        </ul>
                    </div>
                    <div class="right-box">
                        <div class="title">
                            数字云帆 IOT-MES
                        </div>
                        <ul>
                            <li v-for="i in zhenduiRightData" :key="i.label">
                                <div class="content-text">
                                    <strong>{{i.label}}</strong>：{{i.text}}
                                </div>
                                <div class="icon-box">
                                    <img src="../assets/production/gou.png" alt="">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="advantage">
            <div class="advantage-content">
                <div class="commons-title phone-dn"  :class="coreAnimate2" style="margin: 100px 0 100px;">
                    基于物联网的生产制造系统解决方案
                </div>
                <div class="commons-title phone-db"  :class="coreAnimate2">
                    基于物联网的生产制造系统解决方案
                </div>
            </div>
        </div>
        <div class="customer" >
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate3">
                    <img src="../assets/production/img_tgsctmd.png" alt="">
                </div>
                <div class="text" :class="coreAnimate3">
                    <div>
                        <img src="../assets/production/icon_tgsctmd.png" alt="">
                        <h3>提高生产透明度</h3>
                    </div>
                    <p>通过实时数据采集和分析，我们的IoT-MES系统能够提供全面的生产透明度，让您随时掌握生产过程的每一个环节。</p>
                    <img class="phone-db" src="../assets/h5-production/img_tgsctmd.png" alt=""/>
                </div>
            </div>
        </div>
        <div class="black-warp" >
            <div class="black-content">
                <div class="text" :class="coreAnimate4">
                    <div>
                        <img src="../assets/production/icon_shisjk.png" alt="">
                        <h3>实时监控</h3>
                    </div>
                    <p>我们的IoT-MES系统能够实时采集和分析生产数据，无需人工干预，让您可以在任何时间、任何地点掌握生产全局。</p>
                </div>
                <div class="img" :class="coreAnimate4">
                    <img class="phone-dn" src="../assets/production/img_shisjk.png" alt="">
                    <img class="phone-db" style="width:100%" src="../assets/h5-production/img_shisjk.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer" >
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate5">
                    <img src="../assets/production/img_zhunqww.png" alt="">
                </div>
                <div class="text" :class="coreAnimate5">
                    <div>
                        <img src="../assets/production/icon_zhunqww.png" alt="">
                        <h3>准确无误</h3>
                    </div>
                    <p>通过自动化数据采集，我们的系统能够避免人为输入错误，提供准确的数据支持，从而帮助您做出更正确的决策。</p>
                    <img class="phone-db" style="width:100%" src="../assets/h5-production/img_zhunqww.png" alt="">
                </div>
            </div>
        </div>
        <div class="black-warp">
            <div class="black-content">
                <div class="text"  :class="coreAnimate6">
                    <div>
                        <img src="../assets/production/icon_tsxxl.png" alt="">
                        <h3>提升小效率</h3>
                    </div>
                    <p>IoT-MES系统自动处理大量数据，减少了人工操作，使生产过程更加流畅，帮助您提高生产和管理效率。</p>
                </div>
                <div class="img"  :class="coreAnimate6">
                    <img class="phone-dn" src="../assets/production/img_tsxxl.png" alt="">
                    <img class="phone-db" style="width:100%"  src="../assets/h5-production/img_tsxxl.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer">
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate7">
                    <img src="../assets/production/img_zhinyc.png" alt="">
                </div>
                <div class="text" :class="coreAnimate7">
                    <div>
                        <img src="../assets/production/icon_zhinyc.png" alt="">
                        <h3>智能预测</h3>
                    </div>
                    <p>我们的系统利用历史和实时数据进行分析，预测设备故障、生产瓶颈等问题，帮助您提前做出决策，避免可能的生产损失。</p>
                    <img class="phone-db" style="width:100%" src="../assets/h5-production/img_zhinyc.png" alt="">
                </div>
            </div>
        </div>
        <div class="black-warp">
            <div class="black-content">
                <div class="text"  :class="coreAnimate8">
                    <div>
                        <img src="../assets/production/icon_zhiljk.png" alt="">
                        <h3>质量监控</h3>
                    </div>
                    <p>通过实时监控生产数据，我们的IoT-MES系统能够及时发现质量问题，确保您的产品始终保持最高的质量标准。</p>
                </div>
                <div class="img"  :class="coreAnimate8">
                    <img class="phone-dn" src="../assets/production/img_zhiljk.png" alt="">
                    <img class="phone-db" style="width:100%"  src="../assets/h5-production/img_zhiljk.png" alt="">
                </div>
            </div>
        </div>
        <div class="customer">
            <div class="customer-content">
                <div class="img phone-dn" :class="coreAnimate9">
                    <img src="../assets/production/img_kzhnl.png" alt="">
                </div>
                <div class="text" :class="coreAnimate9">
                    <div>
                        <img src="../assets/production/icon_kzhnl.png" alt="">
                        <h3>客制化能力</h3>
                    </div>
                    <p>我们的IoT-MES系统具备强大的客制化能力，可以根据您的具体需求进行个性化配置和优化，满足您的独特需求。</p>
                    
                    <img class="phone-db" style="width:100%" src="../assets/h5-production/img_kzhnl.png" alt="">
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	data() {
		return {
			programmeListType: 0,
			programmeList: [
				{ name: '客户管理', id: 0, icon: '&#xe6ac;' },
				{ name: '团队管理', id: 1, icon: '&#xe6aa;' },
				{ name: '订单管理', id: 2, icon: '&#xe6b0;' },
				{ name: '流程管理', id: 3, icon: '&#xe6b1;' },
				{ name: '生产管理', id: 4, icon: '&#xe6af;' },
				{ name: '仓储管理', id: 5, icon: '&#xe6ae;' },
			],
			show: false,
			animateClass: 'animate__animated animate__fadeInUp',
			advantageAnimate: 'dn',
			programmeAnimete: 'dn',
			coreAnimate1: 'dn',
            coreAnimate2: 'dn',
            coreAnimate3: 'dn',
            coreAnimate4: 'dn',
            coreAnimate5: 'dn',
            coreAnimate6: 'dn',
            coreAnimate7: 'dn',
            coreAnimate8: 'dn',
            coreAnimate9: 'dn',
            coreAnimate10: 'dn',
            coreAnimate11: 'dn',
            coreAnimate12: 'dn',
            coreAnimate13: 'dn',
            coreAnimate14: 'dn',
            zhenduiLeftData:[
                {
                    label:'延迟性',
                    text:'：普通的MES系统通常依赖人工录入数据，不能实时反映生产状况。在需要快速响应或做出决策的情况下，这种延迟可能会造成生产效率下降或质量问题。'
                },
                {
                    label:'数据误差',
                    text:'：由于依赖人工录入，普通MES系统可能存在数据错误或遗漏的问题，这会影响到生产管理的准确性和效率。'
                },
                {
                    label:'效率低下',
                    text:'：普通的MES系统需要人工录入和处理数据，相比IoT的MES系统，其操作效率较低，这可能会影响到生产和管理的效率'
                },
                {
                    label:'缺乏预测能力',
                    text:'：普通的MES系统主要处理当前的生产信息，缺乏利用历史和实时数据进行预测的能力，这可能会影响到生产的稳定性和效率。'
                },
                {
                    label:'难以实现智能优化',
                    text:'：普通的MES系统通常只能依据预设的规则进行操作，难以利用大数据分析和机器学习等技术进行智能优化，这可能会影响到生产效率和产品质量。'
                },
                {
                    label:'可扩展性差',
                    text:'：普通的MES系统由于依赖人工操作，难以适应大规模的扩展，这可能会限制企业的发展。'
                }
            ],
            zhenduiRightData:[
                {
                    label:'实时性',
                    text:'：IoT的MES系统可以实时采集生产数据，提供实时的生产信息，比如设备状态、生产进度、质量参数等。相比之下，传统的MES系统通常依赖人工录入，可能会有延迟，且在紧急情况下可能无法及时响应。'
                },
                {
                    label:'准确性',
                    text:'：IoT的MES系统通过自动化的数据采集方式，避免了人工录入的误差，提供了更高的数据准确性。而传统的MES系统由于依赖人工录入，可能存在录入错误或遗漏等问题。'
                },
                {
                    label:'高效性',
                    text:'：IoT的MES系统可以自动化处理大量数据，减少了大量的人工操作，提高了生产和管理效率。而传统的MES系统需要人工录入数据，操作效率较低。'
                },
                {
                    label:'预测能力',
                    text:'：基于IoT的MES系统可以利用历史和实时数据进行分析，预测设备故障、生产瓶颈等问题，提前做出决策。而传统的MES系统通常只能处理当前的生产信息，缺乏预测能力。'
                },
                {
                    label:'智能优化',
                    text:'：IoT的MES系统可以通过大数据分析和机器学习等技术，对生产流程进行智能优化，提高生产效率和产品质量。而传统的MES系统通常只能依据预设的规则进行操作，难以实现智能优化。'
                },
                {
                    label:'可扩展性',
                    text:'：IoT的MES系统可以很方便地扩展到更多的设备和生产线，支持企业的发展。而传统的MES系统由于依赖人工操作，难以适应大规模的扩展。'
                }
            ],
		}
	},
	methods: {
		handleScroll(e) {
            if(window.innerWidth > 800){
                if (document.documentElement.scrollTop > 400) {
                    this.advantageAnimate = this.animateClass
                }
                if (document.documentElement.scrollTop > 1300) {
                    this.programmeAnimete = this.animateClass
                }
                if (document.documentElement.scrollTop > 2100) {
                    this.coreAnimate1 = this.animateClass
                }
                if (document.documentElement.scrollTop > 2700) {
                    this.coreAnimate2 = this.animateClass
                }
                if (document.documentElement.scrollTop > 2800) {
                    this.coreAnimate3 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3400) {
                    this.coreAnimate4 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4000) {
                    this.coreAnimate5 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4400) {
                    this.coreAnimate6 = this.animateClass
                }
                if (document.documentElement.scrollTop > 5000) {
                    this.coreAnimate7 = this.animateClass
                }
                
                if (document.documentElement.scrollTop > 5800) {
                    this.coreAnimate8 = this.animateClass
                }
                if (document.documentElement.scrollTop > 6200) {
                    this.coreAnimate9 = this.animateClass
                }
                if (document.documentElement.scrollTop > 7800) {
                    this.coreAnimate10 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8400) {
                    this.coreAnimate11 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8500) {
                    this.coreAnimate12 = this.animateClass
                }
                if (document.documentElement.scrollTop > 9000) {
                    this.coreAnimate13 = this.animateClass
                }
                if (document.documentElement.scrollTop > 9600) {
                    this.coreAnimate14 = this.animateClass
                }
            }else{
                if (document.documentElement.scrollTop > 200) {
                    this.advantageAnimate = this.animateClass
                }
                if (document.documentElement.scrollTop > 1300) {
                    this.programmeAnimete = this.animateClass
                }
                if (document.documentElement.scrollTop > 1700) {
                    this.coreAnimate1 = this.animateClass
                }
                if (document.documentElement.scrollTop > 2100) {
                    this.coreAnimate2 = this.animateClass
                }
                if (document.documentElement.scrollTop > 2500) {
                    this.coreAnimate3 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3000) {
                    this.coreAnimate4 = this.animateClass
                }
                if (document.documentElement.scrollTop > 3500) {
                    this.coreAnimate5 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4000) {
                    this.coreAnimate6 = this.animateClass
                }
                if (document.documentElement.scrollTop > 4500) {
                    this.coreAnimate7 = this.animateClass
                }

                if (document.documentElement.scrollTop > 5000) {
                    this.coreAnimate8 = this.animateClass
                }
                if (document.documentElement.scrollTop > 5500) {
                    this.coreAnimate9 = this.animateClass
                }
                if (document.documentElement.scrollTop > 6000) {
                    this.coreAnimate10 = this.animateClass
                }
                if (document.documentElement.scrollTop > 6500) {
                    this.coreAnimate11 = this.animateClass
                }
                if (document.documentElement.scrollTop > 7500) {
                    this.coreAnimate12 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8000) {
                    this.coreAnimate13 = this.animateClass
                }
                if (document.documentElement.scrollTop > 8600) {
                    this.coreAnimate14 = this.animateClass
                }
            }
			
		},
	},
	created() {
		const v = this
		window.addEventListener('scroll', this.handleScroll)
		
	},
}
</script>
<style>
.swiper-pagination-bullet {
	background: #fff;
	width: 20px;
	height: 4px;
	border-radius: 0px;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background: #487cff;
}
</style>
<style lang='less' scoped>
.erp {
    .frid-box{
        background: #3678fc;
        height: 120px;
        .frid-box-bg{
            height:120px;
            background: url(../assets/pro/img_tl.png) no-repeat;
            background-size: auto 120px;
            background-position: center center;
        }
    }
    .rfid{
        background: url(../assets/pro/img_bjht.png) no-repeat;
        background-size: cover;
        padding-top: 120px;
    }
    .vs{
        background: url(../assets/erp/img_vs.png) no-repeat;
        background-size: cover;
        position: relative;
        .vs-img{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -60px 0 0 -60px;
        }
        .vs-content{
            display: flex;
            margin: 0 auto;
            width: 1200px;
            .left-box{
                width: 50%;
                height: 500px;
                margin-top:20px;
                padding: 0 60px;
                .text-2{
                    color: #fff;
                    transform: skew(-15deg);
                    div{
                        transform: skew(15deg);
                    }
                }
                li::before{
                    content:"";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background: #fff;
                    position: absolute;
                    top:21px;
                    left: 0;
                    
                }
            }
            .right-box{
                width: 50%;
                height: 500px;
                margin-top:20px;
                padding: 0 60px;
                .text-1{
                    color: #fff;
                    padding-left: 80px;
                    position: relative;
                    i{
                        color:#fff;
                    }
                }
                .text-1::before{
                    content:"";
                    display: block;
                    width: 50px;
                    height: 58px;
                    background: url(../assets/erp/icon_xx.png);
                    position: absolute;
                    left: 0;
                    top:-6px;
                }
                .text-2{
                    background: #fff;
                    color: #487CFF;
                    transform: skew(-15deg);
                    div{
                        transform: skew(15deg);
                    }
                }
                li{
                    padding-left: 0px;
                    i{
                        color:#fed92e;
                        margin-right:25px;
                        font-size: 20px;
                    }
                }
            }
            .text-1{
                font-size: 30px;
                margin-top: 60px;
                color: #487CFF;
                font-weight: bold;
                padding-left: 40px;
                i{
                    font-size: 40px;
                    float: right;
                    color: #487CFF;
                }
            }
            .text-2{
                height: 60px;
                line-height: 60px;
                margin-top: 50px;
                background: #487CFF;
                margin-bottom: 30px;
                text-align: center;
                font-weight: bold;
                font-size: 20px;
            }
            ul{
                
                li{
                    list-style: none;
                    font-size: 18px;
                    color: #fff;
                    line-height: 56px;
                    padding-left: 40px;
                    position: relative;
                }
            }
        }
        
    }
    .black-warp{
        background: #f1f1f1;
        .black-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            height: 590px;
            justify-content: space-between;
            padding: 90px 0 100px;
            .img{}
            .text{
                width: 480px;
                padding-left: 90px;
                padding-top: 74px;
                h3{
                    color: #333;
                    font-size: 32px;
                    margin: 15px 0;
                }
                p{
                    color: #666;
                    font-size: 16px;
                    line-height: 32px;
                }
            }
        }
    }
    .customer{
        background: #fff;
        .customer-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            height: 590px;
            justify-content: space-between;
            padding: 90px 0 100px;
            .img{}
            .text{
                width: 480px;
                padding-right: 90px;
                padding-top: 74px;
                h3{
                    color: #333;
                    font-size: 32px;
                    margin: 15px 0;
                }
                p{
                    color: #666;
                    font-size: 16px;
                    line-height: 32px;
                }
            }
        }
    }
	.zhendui{
        background: url(../assets/pro/img_ctcc.png) no-repeat;
        background-size: cover;
        height: 1120px;
        position: relative;
        .zhendui-logo{
            position: absolute;
            top: -70px;
            left:50%;
            margin-left: -70px;
        }
        .zhendui-content{
            padding-top:80px;
            width: 1200px;
            margin: 0 auto;
            position: relative;
            .center-icon{
                position: absolute;
                left: 50%;
                top: 70%;
                margin: -115px 0 0 -115px;
            }
            .content-warp{
                display: flex;
                justify-content: space-between;
            }
            .left-box,.right-box{
                overflow: hidden;
                width: 590px;
                .title{
                    height: 80px;
                    background: linear-gradient(180deg, #64A5FF 0%, #3564F5 100%);
                    line-height: 80px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    padding: 0 20px;
                    color: #fff;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            .right-box{
                .title{
                    text-align: right;
                }
                ul{
                    
                    li{
                        background: #EBF2FF;
                        overflow: hidden;
                        padding-top: 14px;
                        height:100px;
                        padding-left: 80px;
                        line-height: 26px;
                        margin-top:20px;
                        .icon-box{
                            width: 44px;
                            float: left;
                            img{
                                width: 14px;
                                height: 14px;
                                margin-left: 10px;
                            }
                        }
                        .content-text{
                            width: calc(100% - 44px);
                            float: left;
                            font-size: 14px;
                            color:#333;
                        }
                    }
                }
            }
            .left-box{
                .title{
                    text-align: right;
                }
                ul{
                    
                    li{
                        background: #fff;
                        overflow: hidden;
                        padding-top: 14px;
                        height:100px;
                        padding-right: 80px;
                        line-height: 26px;
                        margin-top:20px;
                        .icon-box{
                            width: 44px;
                            float: left;
                            img{
                                width: 14px;
                                height: 14px;
                                margin-left: 20px;
                            }
                        }
                        .content-text{
                            width: calc(100% - 44px);
                            float: left;
                            font-size: 14px;
                            color:#333;
                        }
                    }
                }
            }
            
        }
    }

	.painPoint {
		height: 820px;
		margin: 0 auto;
        background: url(../assets/production/img_bjgdxt.png) no-repeat;
        background-size: cover;
		ul{
            width: 1200px;
            margin: 60px auto 0;
            display: flex;
            justify-content: space-between;
            //排不下换行
            flex-wrap: wrap;
            li{
                width: 386px;
                height: 240px;
                background: #FFFFFF;
                box-shadow: 0px 2px 20px 1px rgba(100,165,255,0.1);
                border-radius: 10px 10px 10px 10px;
                padding: 30px 20px;
                box-sizing: border-box;
                margin-bottom: 40px;
                .icon-box{
                    width: 60px;
                    height: 60px;
                }
                .title{
                    line-height: 50px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #333;
                }
                .content-text{
                    font-size: 14px;
                    color: #666;
                    line-height: 30px;
                }
            }
        }
	}
	.swiper {
		width: 100%;
		height: 100vh;
		min-height: 840px;
		.swiper-slide {
			overflow: hidden;
			background: #00072d;
			height: 100vh;
			min-height: 840px;
		}
		.banner-btn {
			margin: 0 auto;
			width: 180px;
			height: 60px;
			background: #487cff;
			color: #fff;
			text-align: center;
			line-height: 60px;
			margin-block: 70px;
			cursor: pointer;
		}
		.banner-btn:active {
			background: #fff;
			color: #487cff;
		}
		.content-box {
			background: url(../assets/production/img_banner_bj.png) no-repeat;
			height: 100vh;
			min-height: 840px;
			background-size: cover;
			margin: auto;
			div {
				padding-top: 240px;
			}
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}
    @media only screen and (max-width: 800px) {
		.black-warp {
			background: #f1f1f1;
			.black-content {
				width: 100%;
				margin: 0 auto;
				display: block;
				height: auto;
				justify-content: space-between;
                //pa
				padding: 50px 12px;
				.img {
				}
				.text {
					width: 100%;
					padding: 0;
					div {
						display: flex;
						text-align: center;
						justify-content: center;
						align-items: center;
						img {
							width: 25px;
							height: 25px;
							margin-right: 8px;
						}
					}
					h3 {
						color: #333;
						font-size: 20px;
						margin: 15px 0;
					}
					p {
						color: #666;
						font-size: 16px;
						line-height: 32px;
					}
					.phone-db {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
		.customer {
			background: #fff;
			.customer-content {
				width: 100%;
				margin: 0 auto;
				display: block;
				height: auto;
				justify-content: space-between;
				padding: 50px 12px;

				.img {
				}
				.text {
					width: 100%;
					padding-right: 0;
					padding-top: 0px;
					div {
						display: flex;
						text-align: center;
						justify-content: center;
						align-items: center;
						img {
							width: 25px;
							height: 25px;
							margin-right: 8px;
						}
					}
					h3 {
						color: #333;
						font-size: 20px;
						margin: 15px 0;
					}
					p {
						color: #666;
						font-size: 16px;
						line-height: 32px;
					}
					.phone-db {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}
		.zhendui {
			background: url(../assets/h5-erp/img_bl.png) no-repeat;
			background-size: cover;
            padding-bottom: 40px;
            height: auto;
			.zhendui-logo{
                width: 70px;
                height: 70px;
                position: absolute;
                top: -35px;
                left:50%;
                margin-left: -35px;
                img{
                    width: 100%;
                }
            }
			.zhendui-content {
				padding-top: 50px;
				width: 100%;
				margin: 0 auto;
			}
		}
		.swiper-slide {
			background: #fff !important;
		}
		.swiper {
			.content-box {
				background: url(../assets/h5-production/banner_mes.png) no-repeat;
				background-size: cover;
    			background-position: center center;
				img {
					width: 90%;
					margin: 0 5%;
				}
                div{
                    padding-top:0;
                }
			}
		}
		.trial {
			height: 100px;
		}
		.case {
			.case-content {
				width: 100%;
			}
		}
		.core {
			height: auto;
			.core-content {
				width: 100%;
				img {
					width: 90%;
				}
			}
		}
		.painPoint {
            height: auto;
            padding-top:38px!important;
			ul{
                padding: 0 12px;
                width: 100%;
                li{
                    display: block;
                    position: relative;
                    height: auto;
                    padding: 20px 12px 20px 64px;
                    margin-bottom: 20px;
                    width: 100%;
                    .icon-box{
                        position: absolute;
                        top: 20px;
                        left: 12px;
                        width: 40px;
                        height: 40px;
                        img{
                            width: 100%;
                        }
                    }
                    .title{
                        height: 20px;
                        line-height: 20px;
                        color: #333;
                        font-size: 14px;
                    }
                    .content-text{
                        font-size: 14px;
                        color: #333;
                        line-height: 20px;
                        margin-top: 10px;
                    }
                }
            }
		}
		.advantage {
			.advantage-content {
				width: 100%;
				padding: 35px 24px;
				ul {
					li {
						width: 100%;
						display: flex;
						.img-box {
							width: 60px;
							height: 60px;
						}
						.line {
							width: 1px;
							height: 62px;
						}
					}
				}
			}
		}
	}
}
</style>